import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const UpdateMedicamentVariantForm = ({ variant, medicamentId, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    medoc_ou_complement_ou_phyto: '',
    dose: '',
    frequence: '',
    duree_traitement: '',
    conseil_utilisation: '',
    tableau_ou_pas: '',
    age_specifique_medic: '',
    min_age_enfant: '',
    max_age_enfant: '',
    mise_en_garde: '',
    sexe_specifique_medic: '',
    femme_enceinte: '',
    allaitement: '',
    hta: '',
    diabete: '',
    epilepsie: '',
    tags: '',
    legal_class: '',
    entry_type: '',
    rules: '',
    traitement_appoint: '',
    medicamentId: medicamentId,
    pathologyId: '',
  });

  const [pathologies, setPathologies] = useState([]);

  useEffect(() => {
    setFormData(variant);

    // Fetch pathologies
    const fetchPathologies = async () => {
      try {
        const response = await axios.get('pathologies', {
            headers: { 
              "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
              Authorization: `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`,
            },
          });;
        setPathologies(response.data);
      } catch (error) {
        console.error('Error fetching pathologies:', error);
      }
    };

    fetchPathologies();
  }, [variant, medicamentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`medicament_variants/${variant._id}`, formData, {
        headers: {
          "x-access-token":
            localStorage.getItem("token") || sessionStorage.getItem("token"),
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      });
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="pathology-label">Pathology</InputLabel>
        <Select
          labelId="pathology-label"
          name="pathologyId"
          value={formData.pathologyId}
          onChange={handleChange}
        >
          {pathologies.map((pathology) => (
            <MenuItem key={pathology._id} value={pathology._id}>
              {`${pathology.appareilId.appareil} - ${pathology.pathologie}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField label="Dose" name="dose" value={formData.dose} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Frequence" name="frequence" value={formData.frequence} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Duree Traitement" name="duree_traitement" value={formData.duree_traitement} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Conseil Utilisation" name="conseil_utilisation" value={formData.conseil_utilisation} onChange={handleChange} fullWidth margin="normal" />
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Age Spécifique Medic</InputLabel>
        <Select
          name="age_specifique_medic"
          value={formData.age_specifique_medic || ""}
          onChange={handleChange}
        >
          <MenuItem value="">Vide</MenuItem>
          <MenuItem value="A">A</MenuItem>
          <MenuItem value="E">E</MenuItem>
        </Select>
      </FormControl>
      
      <TextField label="Min Age Enfant" name="min_age_enfant" value={formData.min_age_enfant} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Max Age Enfant" name="max_age_enfant" value={formData.max_age_enfant} onChange={handleChange} fullWidth margin="normal" />
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Femme Enceinte</InputLabel>
        <Select
          name="femme_enceinte"
          value={formData.femme_enceinte || ""}
          onChange={handleChange}
        >
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Allaitement</InputLabel>
        <Select
          name="allaitement"
          value={formData.allaitement || ""}
          onChange={handleChange}
        >
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Diabète</InputLabel>
        <Select
          label="Diabète"
          name="diabete"
          value={formData.diabete || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>HTA</InputLabel>
        <Select
          label="HTA"
          name="hta"
          value={formData.hta || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Épilepsie</InputLabel>
        <Select
          label="Épilepsie"
          name="epilepsie"
          value={formData.epilepsie || ""}
          onChange={handleChange}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
          <MenuItem value="non">Non</MenuItem>
        </Select>
      </FormControl>
      <TextField label="Tags" name="tags" value={formData.tags} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Entry Type" name="entry_type" value={formData.entry_type} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Rules" name="rules" value={formData.rules} onChange={handleChange} fullWidth margin="normal" />
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Traitement Appoint</InputLabel>
        <Select
          name="traitement_appoint"
          value={formData.traitement_appoint || ""}
          onChange={handleChange}
        >
          <MenuItem value="">Vide</MenuItem>
          <MenuItem value="oui">Oui</MenuItem>
        </Select>
      </FormControl>

      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default UpdateMedicamentVariantForm;
