import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { WarningAmber as WarningIcon } from "@mui/icons-material";

const ConfirmationDialog = ({ 
  open, 
  onClose, 
  onConfirm, 
  title = "Are you sure?", 
  message = "Are you sure you want to proceed?", 
  confirmText = "Confirm", 
  cancelText = "Cancel", 
  confirmColor = "error" 
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" 
      fullWidth 
      sx={{
        "& .MuiPaper-root": { 
          borderRadius: "12px", 
          padding: "20px", 
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" 
        }
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <WarningIcon sx={{ fontSize: 48, color: "#f57c00", marginBottom: 1 }} />
        <DialogTitle sx={{ textAlign: "center", fontWeight: 600 }}>
          {title}
        </DialogTitle>
      </Box>

      <DialogContent>
        <Typography variant="body1" color="text.secondary" align="center">
          {message}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
        <Button 
          onClick={onClose} 
          variant="outlined" 
          color="primary" 
          sx={{ borderRadius: "8px", padding: "8px 20px" }}
        >
          {cancelText}
        </Button>
        <Button 
          onClick={onConfirm} 
          variant="contained" 
          color={confirmColor} 
          sx={{ borderRadius: "8px", padding: "8px 20px" }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
