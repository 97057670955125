import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };

  return (
    <ListItem component="div" onClick={handleLogout} sx={{ cursor: "pointer" }}>
      <ListItemIcon>
        <ExitToAppIcon sx={{ color: "red" }}/>
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  );
};

export default LogoutButton;
